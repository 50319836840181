.route__domains{
    @keyframes animateModal {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    
    animation: animateModal .1s linear;
    padding: 20px;
    
    &__newDomainModal {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal .5s linear;
        top: 0;
        left: 0;

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10%;
            padding: 20px;

            h1 {
                margin-bottom: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
            }

            #controles {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }


            #checkbox-span {
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;
                margin-top: 10px;
                width: 100%;
                margin-bottom: 20px;

                &:first-child {
                    margin-top: 0;
                }

                input {
                    margin-right: 10px;
                }

                p {
                    font-family: 'Inter';
                    color: white;
                    font-size: 15px;
                }
            }
            #img-p{
                color: white;
                font-family: 'Inter';
                display: block;
                margin-left: 0;
                margin-right: auto;
                margin-bottom: 10px;
                font-size: 13px;
            }

            input::-webkit-file-upload-button {
                visibility: hidden;
            }

            #img-input::before {
                content: "▲ Upload logo";
                color: #fff;
                display: inline-block;
                background: rgb(63, 124, 234);
                outline: none;
                white-space: nowrap;
                cursor: pointer;
                font-weight: 400;
                padding: 5px;
                border-radius: 2px;
                outline: none;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
            }

            #img-input:focus {
                outline: none !important;
            }

            #img-input:active::before {
                transform: scale(.9) translate(0px, 2px);
                box-shadow: inset 4px 4px 5px 0px rgba(0, 0, 0, 0.20);
            }

            input[type='file'] {
                border: none;
                width: 150px;
                margin-left: 0;
                margin-right: auto;
                display: block;
                margin-bottom: 10px;
            }

            #msg {
                color: red;
                font-family: 'Inter';
                font-size: 15px;
                margin-top: 10px;
            }
        }
    }

    &__editDomainModal {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal .5s linear;
        top: 0;
        left: 0;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgba(0, 0, 0, 0.1);
            z-index: 999;
        }

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10%;
            padding: 20px;

            h1 {
                margin-bottom: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
            }

            #controles {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }


            #checkbox-span {
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;
                margin-top: 10px;
                width: 100%;
                margin-bottom: 20px;

                &:first-child {
                    margin-top: 0;
                }

                input {
                    margin-right: 10px;
                }

                p {
                    font-family: 'Inter';
                    color: white;
                    font-size: 15px;
                }
            }
            #img-p{
                color: white;
                font-family: 'Inter';
                display: block;
                margin-left: 0;
                margin-right: auto;
                margin-bottom: 10px;
                font-size: 13px;
            }

            input::-webkit-file-upload-button {
                visibility: hidden;
            }

            #img-input::before {
                content: "▲ Upload new logo";
                color: #fff;
                display: inline-block;
                background: rgb(63, 124, 234);
                outline: none;
                white-space: nowrap;
                cursor: pointer;
                font-weight: 400;
                padding: 5px;
                border-radius: 2px;
                outline: none;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
            }

            #img-input:focus {
                outline: none !important;
            }

            #img-input:active::before {
                transform: scale(.9) translate(0px, 2px);
                box-shadow: inset 4px 4px 5px 0px rgba(0, 0, 0, 0.20);
            }

            input[type='file'] {
                border: none;
                width: 150px;
                margin-left: 0;
                margin-right: auto;
                display: block;
                margin-bottom: 10px;
            }

            #msg {
                color: red;
                font-family: 'Inter';
                font-size: 15px;
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 911px){
    .route__domains__newDomainModal #box{
        width: 95%;
        margin-top: 25%;
    }
    .route__domains__editDomainModal #box{
        width: 95%;
        margin-top: 25%;
    }
}