.component__contentHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #48525C;
    transform: rotate(-0.07deg);
    padding-bottom: 8.5px;

    &__clientDataModal{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: rgba($color: #000000, $alpha: 0.6);

        &__box{
            background: #192343;
            min-width: 500px;
            width: fit-content;
            height: fit-content;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: baseline;
            align-items: baseline;
            border-radius: 5px;

            &__close{
                width: 24px !important;
                height: 24px !important;
                margin-left: auto;
                margin-right: 0;
                display: block;
                margin-bottom: 5px;
                cursor: pointer;
            }

            &__image{
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                width: 100%;
                height: auto;
                margin-bottom: 20px;
                &__banner{
                    width: 100%!important;
                    height: auto!important;
                }
                &__img{
                    position: absolute;
                    min-width: 122px!important;
                    min-height: 122px !important;
                    max-width: 500px !important;
                    max-height: 500px !important;

                    width: auto;
                    height: auto;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 10px;
                }

                &__badge{
                    position: absolute;
                    min-width: 200px!important;
                    min-height: 200px !important;
                    max-width: 500px !important;
                    max-height: 500px !important;
                    z-index: 10;

                    width: auto;
                    height: auto;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 10px;
                }
            }
            

            span{
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;
                gap: 10px;
                margin-bottom: 10px;

                strong, p{
                    font-size: 15px;
                }
            }

            &__lookingFor, &__bio{
                display: flex;
                flex-direction: column;
                justify-content: baseline;
                align-items: baseline;
                strong, p{
                    font-size: 15px;
                }
                margin-bottom: 10px;
            }
        }
    }

    &__clientInfo {
        display: flex;
        flex-direction: row;
        justify-content: baseline;
        align-items: center;
        gap: 12px;

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        &__viewProfileButton {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 12px;
            width: 159px;
            height: 36px;
            background: #0099FF;
            border-radius: 100px;
            border: none;
            cursor: pointer;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;

            img {
                border-radius: 0;
                width: 18px;
                height: 17px;
            }
        }

        &__viewProfileButton {
            background: #48525C;

            p {
                color: white;
            }

            img {
                width: 16px;
                height: 18px;
            }
        }
    }
}

@media screen and (max-width: 522px){
    .component__contentHeader__clientDataModal__box{
        min-width: 95%;
        width: 95%;
    }
}
.component__userNotes{
    width: 500px;
    max-width: 100%;
    height: 500px;
    max-height: 100%;
    background-color: #000;
    padding: 20px;
    border-radius: 2px;
    &>textarea{
        width: 100%;
        height: 85%;
        overflow-y: scroll;
        background-color: transparent;
        outline: none;
        padding: 10px;
        font-family: 'Inter';
        color: white;
    }
    &>div{
        display: flex;
        align-items: center;
        justify-content: center;
        &>button{
            width: 100px;
            height: 40px;
            background-color: transparent;
            border: 1px solid white;
            border-radius: 50px;
            cursor: pointer;
            color: white;
            font-family: 'Inter';
            font-size: 20px;
            font-weight: 700;
        }
    }
}

@media screen and (max-width: 706px){
    .component__contentHeader{
        flex-direction: column;
        gap: 10px;
    }
    .component__contentHeader__buttons__viewProfileButton{
        width: fit-content;
        font-size: 10px;
        gap: 3px;
        padding-left: 5px;
        padding-right: 5px;
    }
}