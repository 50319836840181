.retention-rate-table{
    display: grid;
    color: white;
    max-width: 100%;
    overflow-x: scroll;
    border: 1px solid #2e4c6e;
    border-radius: 10px;
}

.retention-rate-table__header{
    display: grid;
    gap: 8px;
    padding: 4px;
    margin-bottom: 10px;
    font-family: 'Inter';
    color: white;
    font-size: 16px;
    text-align: center;
}
.retention-rate-table__header p{
    display: flex;
    justify-content: center;
    align-items: center;
}

.retention-rate-table__header__mainPara{
    position: relative;
    padding: 10px;
    text-align: center;
}

.retention-rate-table__body{
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    color: white;
    font-size: 16px;
    text-align: center;
}
.retention-rate-table__body__item{
    display: grid;
    gap: 8px;
    padding: 4px;
    padding-bottom: 6px;
    margin-bottom: 6px;
}

.retention-rate-table__body__item p{
    display: flex;
    justify-content: center;
    align-items: center;
}

.retention-rate-table__body__item__mainPara{
    position: relative;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.retention-rate-table__body__item__mainPara--span{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 0px;
    gap: 4px;
    transition: 0.3s ease;
}