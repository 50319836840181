@font-face {
    font-family: "Montserrat";
    src: url("../../Fonts/Montserrat-SemiBold.ttf");
}
@font-face {
    font-family: "Inter";
    src: url("../../Fonts/Inter-VariableFont_slnt\,wght.ttf");
}
@keyframes customTableDataTransitionOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes delay-overflow {
    from { overflow: hidden; }
  }
.customComponents__table {
    all: initial;
    display: block;
    border-radius: 5px;
    width: calc(100% - 4px);
    opacity: 0;
    font-size: initial !important;
    font-weight: initial !important;
    color: black;
    font-family: "Montserrat";

    &__filterHead {
        width: 100%;
        height: auto;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #efeff6;
        color: black;
        padding: 20px 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: hidden;

        &__filterList {
            display: flex;
            flex-wrap: wrap;
            color: white;

            & > span {
                width: auto;
                overflow: hidden;
                max-width: 0;
                padding: 0;
                background-color: white;
            }

            &__addFilter {
                background-color: transparent !important;
                max-width: 100% !important;
                color: #5F6470;
                font-family: Montserrat;
                margin-right: 10px;
                margin-top: 5px;
                margin-bottom: 5px;
                padding: 10px 5px;
                border-radius: 7px !important;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 40px;
                cursor: default;
                transition: background-color 0.3s ease;

                & > img {
                    filter: invert(1);
                }

                &:hover {
                    background-color: #dededf !important;
                }
            }
        }

        &__filterList > span {
            font-family: Montserrat;
            cursor: pointer;
            border-radius: 5px;
            height: 100%;
            padding: 10px 0;
            display: inline-block;
            transition: background-color 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            color: #5f6470;
            overflow: hidden;
            white-space: nowrap;
            max-width: 0;

            &:hover {
                background-color: #caccf5;
            }
            & span:nth-child(2) {
                margin-left: 5px;
            }
        }
        &__addFilterWrap {
            width: 100%;
            max-height: 0;
            height: auto;
            margin-top: 0px;
            overflow: hidden;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            background-color: #efeff6;
            transition: max-height 0.3s ease, padding 0.3s ease;
            padding-left: 40px;

            &--active {
                max-height: 100px;
                overflow: initial;
                padding-top: 4px;
                padding-bottom: 4px;
                animation: 1s delay-overflow;
                @media screen and (max-width: 700px) {
                    max-height: 150px;
                }
            }
            div{
                margin-right: 30px;
            }
        }
    }
    &__head {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
        place-items: center;
        grid-auto-flow: column;
        width: 100%;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        background-color: #efeff6;
        padding: 0 40px;
        box-sizing: border-box;

        @media screen and (max-width: 600px) {
            display: none;
        }
        p {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 18px;
            color: #00a3ff;
            @media screen and (max-width: 1200px) {
                font-size: 14px;
            }
        }
    }
    &__data {
        display: grid;
        grid-template-rows: auto;
        grid-auto-rows: auto;
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
        align-items: center;
        background-color: #efeff6;
        padding: 0;
        height: auto;
        max-height: 0;
        box-sizing: border-box;
        transition: height 0.3s ease, background-color 0.3s linear;

        & > * {
            justify-self: center;
            grid-row: 1;
        }
        &__mobileFriendly {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 30px;

            & > * {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-end;
            }
        }
        &__mobile {
            font-weight: bold;
        }
        &__mobileButton {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 30px;
            flex-shrink: 0;
        }
        &__group {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            & > * {
                margin: 5px;
                justify-self: center;
            }
        }
        &__groupNewline {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            margin: 5px;
            flex-wrap: wrap;
            grid-column: 1 / span all;

            & > * {
                margin: 5px;
                justify-self: center;
                flex-grow: 0;
                flex-shrink: 0;
            }
            @media screen and (max-width: 700px) {
                justify-content: flex-end;
                margin-top: 20px;
            }
        }
        &__dropdown {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: auto;
            align-items: center;
            background-color: transparent;
            box-sizing: border-box;
            max-height: 0vh;
            overflow: hidden;
            transition: max-height 1s ease;
        }
        p {
            font-family: Inter;
            font-weight: 400;
            font-size: 16px;
            color: #5e606f;
            word-break: break-all;
        }
        &__customInput {
            min-width: 100%;
            height: auto;
        }
    }
    &__data0 {
        background-color: #e4e4ec;
    }
}
.customComponents__table--dark.customComponents__table {
    color: white;
    
    & .customComponents__table__head,
    & .customComponents__table__filterHead {
        background-color: #282f37;
        color: white;
        p {
            color: white;
        }
    }
    & .customComponents__table__filterHead {
        &__addFilterWrap {
            background-color: #282f37;
        }
        &__filterList__addFilter {
            & > img {
                filter: invert(1);
            }
            &:hover {
                background-color: #192343 !important;
            }
        }
        &__filterList > span {
            color: white;
            background-color: #313943;
            &:hover {
                background-color: #272e36;
            }
            & span:nth-child(2) {
                margin-left: 5px;
            }
        }
    }
    & .customComponents__table__data {
        background-color: rgba(25, 35, 67, 0.50)  ;
        p {
            color: white;
        }
    }
    & .customComponents__table__data0 {
        background-color: rgb(25, 35, 67) ;
    }
}