.route__stickers {
    padding: 20px;
    &__editModal {
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-y: scroll;
        z-index: 3;
        background: rgba(0, 0, 0, 0.377);
        animation: animateModal .5s linear;
        top: 0;
        left: 0;

        #box {
            background: #2F363E;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 400px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 15%;
            padding: 30px;

            h1 {
                margin-bottom: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
            }

            #img-p {
                color: white;
                font-family: 'Inter';
                display: block;
                margin-left: 0;
                margin-right: auto;
                margin-bottom: 10px;
                font-size: 13px;
            }

            input::-webkit-file-upload-button {
                visibility: hidden;
            }

            #img-input::before {
                content: "▲ Upload image";
                color: #fff;
                display: inline-block;
                background: rgb(63, 124, 234);
                outline: none;
                white-space: nowrap;
                cursor: pointer;
                font-weight: 400;
                padding: 5px;
                border-radius: 2px;
                outline: none;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
            }

            #img-input:focus {
                outline: none !important;
            }

            #img-input:active::before {
                transform: scale(.9) translate(0px, 2px);
                box-shadow: inset 4px 4px 5px 0px rgba(0, 0, 0, 0.20);
            }

            input[type='file'] {
                border: none;
                width: 150px;
                margin-left: 0;
                margin-right: auto;
                display: block;
                margin-bottom: 25px;
            }

            #controles {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            #msg {
                color: red;
                font-family: 'Inter';
                text-align: center;
            }

            .selectDomainButton {
                width: 100%;
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 16px;
            }
        }
    }

    &__addNewModal {
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-y: scroll;
        z-index: 3;
        background: rgba(0, 0, 0, 0.377);
        animation: animateModal .5s linear;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;

        #box {
            background: #2F363E;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 400px;
            margin-left: auto;
            margin-right: auto;
            padding: 30px;

            #img-p {
                color: white;
                font-family: 'Inter';
                display: block;
                margin-left: 0;
                margin-right: auto;
                margin-bottom: 10px;
                font-size: 13px;
            }

            input::-webkit-file-upload-button {
                visibility: hidden;
            }

            #img-input::before {
                content: "▲ Upload image";
                color: #fff;
                display: inline-block;
                background: rgb(63, 124, 234);
                outline: none;
                white-space: nowrap;
                cursor: pointer;
                font-weight: 400;
                padding: 5px;
                border-radius: 2px;
                outline: none;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
            }

            #img-input:focus {
                outline: none !important;
            }

            #img-input:active::before {
                transform: scale(.9) translate(0px, 2px);
                box-shadow: inset 4px 4px 5px 0px rgba(0, 0, 0, 0.20);
            }

            input[type='file'] {
                border: none;
                width: 150px;
                margin-left: 0;
                margin-right: auto;
                display: block;
                margin-bottom: 25px;
            }

            h1 {
                margin-bottom: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
            }

            #controles {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            #msg {
                color: red;
                font-family: 'Inter';
                text-align: center;
            }

            .selectDomainButton {
                width: 100%;
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 911px) {
    .route__stickers__addNewModal #box {
        width: 95%;
    }

    .route__stickers__editModal #box {
        width: 95%;
    }
}