.route__login {
    background: url(../../../public/images/BG.png);
    background-repeat: no-repeat;
    min-height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

    &__form {
        width: 499px;
        height: 477px;
        background: #2F363E;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        padding: 40px;

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 35px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 24px;
        }

        #msg{
            color: red;
            font-size: 15px;
            margin-top: 10px;
        }

        .loginBtn {
            width: 100%;
            background-color: rgb(63, 124, 234);
            margin-top: 40px;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 27px;
            font-family: 'Inter';
            color: #FFFFFF;
        }
    }
}

@media screen and (max-width: 650px){
    .route__login__form{
        width: 95%;
    }
    .route__login__form p{
        font-size: 23px;
    }
}