.component__chatContent{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    &__startMessage{
        margin-left: auto;
        margin-right: auto;
        color: white;
        font-family: 'Inter';
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 1100px){
    .component__chatContent{
        padding-left: 0;
    }
}

@media screen and (max-width: 710px){
    .component__chatContent{
        height: 77%;
    }
}