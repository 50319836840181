.component__settingsModal{
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, 0.377);
    animation: animateModal .5s linear;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #box {
        background: #2F363E;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        width: 500px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;

        #menu {
            width: 100%;
        }

        .settingsBtns {
            width: 100%;
            margin-top: 10px;
        }

        #controles {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }

        #msg {
            color: red;
            font-family: 'Inter';
            font-size: 15px;
            margin-top: 10px;
        }

        div {
            animation: animateModal .5s linear;
            width: 100%;
        }
    }
}

.component__sidebar{
    background: #1923438e;
    padding: 20px;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    animation: animateNavbar .5s linear;

    @keyframes animateNavbar{
        0%{
            max-height: 0;
        }
        100%{
            max-height: 10000px;
        }
    }

    &__item {
        margin-bottom: 12px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        padding-left: 17px;
        transition: 500ms;
        border: 1px solid #1923438e;
        cursor: pointer;

        &:hover{
            border-color: #2E4C6E;
            // border-radius: 10px;
        }

        div{
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: center;

            img{
                width: 20px;
                height: 20px;
            }

            p {
                font-family: 'Inter';
                color: white;
                font-size: 16px;
                margin-left: 12px;
            }
        }
    }

    #active {
        // background: rgba(25, 35, 67, 1);
        background: #0b1124;
        border: 1px solid #FF7F50;
        // border-radius: 10px;
    }

    #profile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 210px;
        background: #192343;
        border: 1px solid #2E4C6E;
        border-radius: 5px;
        padding-right: 17px;
        bottom: 0;
        cursor: default;
        height: fit-content;
        padding: 10px;
        position: relative;
        p{
            color: white;
            font-family: 'Inter';
        }

        #profile-user{
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: center;
            #profile-image{
                border: none;
                padding: 0;
                margin-bottom: 0;
                margin-right: 10px;
                background: #3f7cea;
                width: 36px;
                height: 36px;
                padding: 5px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                p{
                    margin-bottom: 0;
                }
            }
        }

        #settings-icon {
            border: 1px solid #48525C;
            border-radius: 2px;
            padding: 2px;
            margin-bottom: 0;
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
        }

        #settings-modal {
            position: absolute;
            right: 0px;
            top: -32px;
            padding: 11px;
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.38);
            border: 1px solid #2E4C6E;
            border-radius: 5px;
            height: fit-content;
            z-index: 3;
            flex-direction: column;
            cursor: pointer;
            animation: animateModal .3s linear;

            span {
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    margin-right: 13px;
                }

                p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

@media screen and (max-width: 600px){
    .component__sidebar{
        display: none;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 522px){
    .component__settingsModal #box{
        width: 95%;
    }
}