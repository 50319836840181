.component__imageModal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__box{
        background: #282F37;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        max-height: 1000px;
        padding: 20px;
        border-radius: 5px;

        &__close{
            display: block;
            margin-left: auto;
            margin-right: 0;
            cursor: pointer;
            margin-bottom: 10px;
            width: 24px !important;
            height: 24px !important;
        }

        &__img{
            width: auto;
            height: auto;
            max-width: 100% !important;
            height: 33vh !important;
        }
    }
}