.component__header {
    width: 100%;
    height: 100%;
    background: #192343;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;

    #hamburger{
        display: none;
    }

    span {
        background: transparent;
        background-size: 30px 30px;
        background-position: 5px;
        background-repeat: no-repeat;
        color: #FFFFFF;
        width: 150px;
        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        font-family: 'Inter';
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: unset;
            width: 0%;
            height: 2px;
            transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
            background: #3f7cea;
        }
        &:hover::after{
            width: 100%;
            left: 0;
            right: unset;
        }
    }

    div{
        display: flex;
        flex-direction: row;
        #addNew{
            background-image: url(../../../public/images/addnew.png);
            background-repeat: no-repeat;

        }
    }
}

@media screen and (max-width: 1150px){
    .component__header{
        div{
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: center;
        }
        #hamburger{
            display: block;
            width: 24px;
            height: 20px;
            margin-right: 20px;
        }
    }
}