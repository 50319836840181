.route__emailDashboard {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #080b16;
    padding: 20px 55px;
    &__head {
        background-color: #192343;
        padding: 20px;
        border-radius: 5px;
    }
    &__kpi{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-top: 20px;
        gap: 20px;
        &__single{
            background-color: #192343;
            border-radius: 5px;
            width: 100%;
            &__head{
                background-color: #0e14278e;
                padding: 10px 20px;
                color: #fff;
                font-family: Figtree;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                text-align: left;
                text-align: center;
                height: 80px;
            }
            &__number{
                height: 100px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-family: Figtree;
                font-size: 30px;
                font-style: normal;
                font-weight: 800;
                line-height: 140%;
            }
        }
    }
    &__table{
        display: flex;
        flex-direction: column;
        background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 0.5) 100%);
        overflow-y: auto; 
        max-height: 600px;
        border: 1px solid #2E4C6E;
        margin: 20px 0px;
        &__headline{
          color: #FFF;
          font-family: Figtree;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.28px;
          padding: 8px 20px;
          text-transform: capitalize;
        }
        &__head{
          display: grid;
          grid-template-columns: 200px 140px 140px 140px 150px 120px 150px 150px 150px 150px 150px 150px;
          color: #FFF;
          font-family: Figtree;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.28px;
          padding: 10px 20px;
          background-color: #162d47;
          border-bottom: 1px solid #2E4C6E;
          width: max-content;
          text-transform: capitalize;
          gap: 10px;
          position: sticky;
          top: 0;
          &>p{
            text-align: left;
            word-break: break-word;
            max-width: 200px;
          }
        }
        &__item{
          display: grid;
          grid-template-columns: 200px 140px 140px 140px 150px 120px 150px 150px 150px 150px 150px 150px;
          gap: 10px;
          width: max-content;
          &>p{
            text-align: left;
            word-break: break-word;
            max-width: 200px;
            padding: 10px 8px;
            padding-left: 20px;
            color: #fff;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
    }
}
