.component__internalUsersModal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;

    &__body{
        width: 90%;
        height: 90%;
        padding: 20px;
        padding-top: 70px;
        background: #282F37;
        overflow-y: scroll;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        place-items: top;
        gap: 20px;
        border-radius: 5px;
        position: relative;

        &__search{
            position: absolute;
            top: 10px;
            left: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            input{
                outline: none;
                height: 30px;
                width: 300px;
                border: none;
                font-size: 14px;
                padding-left: 10px;
                border-radius: 5px;
            }
        }

        &__close{
            position: fixed;
            width: 24px !important;
            height: 24px !important;
            right: 30px;
            left: auto;
            top: 30px;
            cursor: pointer;
        }

        &__block{
            background: rgb(72, 82, 92);
            height: fit-content;
            width: 200px;
            padding: 5px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            border-radius: 5px;
            cursor: pointer;
            p{
                font-size: 12px;
                text-align: left;
                color: white;
                font-family: 'Inter';
            }
            img{
                width: 100px;
                height: 100px;
            }
        }
    }
}

.component__newChatModal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
    background-color: rgba(0, 0, 0, 0.5);

    &__body{
        width: 90%;
        height: 90%;
        padding: 20px;
        padding-top: 70px;
        background:#0F1D2A;
        overflow-y: scroll;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        place-items: top;
        gap: 20px;
        border-radius: 5px;
        position: relative;

        &__search{
            position: absolute;
            top: 20px;
            left: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            input{
                outline: none;
                height: 30px;
                width: 300px;
                border: none;
                font-size: 14px;
                padding-left: 10px;
                border-radius: 5px;
            }
        }

        &__close{
            position: fixed;
            width: 24px;
            height: 24px;
            right: 30px;
            left: auto;
            top: 30px;
            cursor: pointer;
        }

        &__block{
            background: #192343;
            height: fit-content;
            width: 200px;
            padding: 5px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            border-radius: 5px;
            cursor: pointer;
            p{
                text-align: left;
                font-size: 12px;
            }
            img{
                width: 50px;
                height: 50px;
            }
        }
    }
}

.component__chatSidebar {
    width: 100%;
    padding-right: 5px;
    overflow-y: scroll;

    &__newChatButton{
        width: 100%;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 16px;
        height: 40px;
        background: #192343;
        border: 1px solid #2E4C6E;
        color: white;
        font-weight: 700;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
    }

    &__searchbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #192343;
        border: 1px solid #2E4C6E;
        border-radius: 2px;
        width: 100%;
        height: 40px;
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 19px;

        input {
            border: none;
            outline: none;
            width: 100%;
            height: 100%;
            background: transparent;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #FFFFFF;

            &::placeholder {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #FFFFFF;
            }
        }
    }

    &__chatRoom {
        display: flex;
        flex-direction: row;
        justify-content: baseline;
        align-items: center;
        gap: 12px;
        width: 100%;
        background: #1923438e;
        border-bottom: 1px solid #2E4C6E;
        padding-left: 20px;
        padding: 12px;
        cursor: pointer;

        #userPhoto {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        &__right {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            #info {
                width: 100%;
                p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFFFF;
                }

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 8px;
                    width: 100%;

                    small {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 12px;
                        color: #FFFFFF;
                    }
                }
            }

            #notifications {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 5px;

                #numdot {
                    width: 13px;
                    height: 13px;
                    background: rgba(244, 92, 206, 0.64);
                    color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 9px;
                    border-radius: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 1744px){
    .component__newChatModal__body, .component__internalUsersModal__body{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1262px){
    .component__newChatModal__body, .component__internalUsersModal__body{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .component__newChatModal__body__close, .component__internalUsersModal__body__close{
        top: 20px;
        right: 20px;
    }
}

@media screen and (max-width: 1006px){
    .component__newChatModal__body, .component__internalUsersModal__body{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 763px){
    .component__newChatModal__body, .component__internalUsersModal__body{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 526px){
    .component__newChatModal__body, .component__internalUsersModal__body{
        grid-template-columns: 1fr;
        place-items: center;
    }
}

@media screen and (max-width: 1100px){
    .component__chatSidebar{
        display: none;
    }
}