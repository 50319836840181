.route__paymentPageStats{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 20px 55px;
  &__item{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid #2E4C6E;
    padding-bottom: 20px;
    &__headline{
      color: #FFF;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      &>span{
        font-size: 14px;
        margin-left: 20px;
      }
    }
    &__buycredit{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      row-gap: 8px;
      background: #1923438e;
      padding: 12px;
      border: 1px solid #2E4C6E;
      &>p{
        color: #FFF;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }
    &__packages{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 400px;
      overflow-y: scroll;
      background: #1923438e;
      padding-top: 0px;
      padding-right: 0px;
      border: 1px solid #2E4C6E;
      position: relative;
      &__head{
        display: grid;
        grid-template-columns: 1fr 200px 150px 150px 100px 100px;
        position: sticky;
        top: 0;
        margin-left: -12px;
        border-bottom: 1px solid #2E4C6E;
        padding: 8px 0px;
        padding-left: 12px;
        width: calc(100% + 12px);
        background-color: #162d47;
        &>p{
          color: #FFF;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          padding-left: 12px;
        }
      }
      &__item{
        display: grid;
        grid-template-columns: 1fr 200px 150px 150px 100px 100px;
        padding: 8px 0px;
        &>p{
          color: #8e8e8e;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          display: flex;
          align-items: center;
          padding-left: 12px;
          &>img{
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}