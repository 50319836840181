.route__surveys{
  padding: 20px;
  display: flex;
  flex-direction: column;
  &__users{
    &__headline{
      color: #FFF;
      font-family: Figtree;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 10px; 
    }
  }
}