.component__contentChat {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 700px;
    overflow-y: scroll;
    background-color: #192343;
    .lady-message {
        margin-left: auto;
        margin-right: 0;
    }

    &__message {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 5px;
        width: fit-content;
        max-width: 45%;
        max-height: fit-content;

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        #personImage {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: baseline;
            height: 82px;
        }

        &__right {
            display: flex;
            flex-direction: column;
            justify-content: baseline;
            align-items: baseline;
            color: white;
            max-width: 100%;

            &__img {
                background: #282f37;
                padding: 20px;
                margin-top: 10px;
                border-radius: 5px;
                max-width: 100%;
                img, video {
                    margin-top: 0;
                    border-radius: 0;
                    width: auto;
                    height: 22vh;
                    max-width: 50vw;
                    max-height: 33vh;
                    max-width: 100%;
                }
            }

            .ladyImgMessage {
                display: block;
                margin-left: auto;
                margin-right: 0;
            }
            .ladyImgMessageBlur {
                position: relative;

                &__box {
                    padding: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;

                    background-color: rgba(0, 0, 0, 0.7);
                    cursor: pointer;

                    & > p {
                        text-align: center;
                    }
                }
            }

            #personName {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #ffffff;

                small {
                    margin-left: 12px;
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    color: #b8b8b8;
                }
            }

            #personMessage {
                background: #18456e;
                border-radius: 5px;
                padding: 20px;
                font-family: "Inter";
                margin-top: 6px;
                max-width: 100%;
                word-break: break-all;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .component__contentChat__message {
        max-width: 100%;
    }
    .component__contentChat {
        padding-right: 10px;
    }
}
