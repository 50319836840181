.route__generalStatistics {
  width: 100%;
  height: 100%;

  overflow: auto;
  background-color: #080B16;

  padding: 20px 55px;

  @media screen and (max-width: 587px) {
    padding: 20px;
  }

  .customComponents__table{
    .customComponents__table__head{
      background-color: #000 !important;
    }
    .customComponents__table__data0{
      background-color: #0c142f !important;
    }
    .customComponents__table__data1{
      background-color: #2f42848f !important;
    }
  }

  &__head {
    color: #FFF;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    margin-bottom: 15px;
  }

  .date-picker-container {
    background: #192343;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: white;
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .date-picker-container input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .date-picker-container button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .date-picker-container button:hover {
    background-color: #45a049;
  }

  .subheader{
    padding: 20px;
    padding-bottom: 0;
    border-radius: 10px;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: #fff;
  }
  .monthSelectionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .date-picker-container {
    background-color: rgb(0, 0, 0);
    border: 1px solid rgb(46, 76, 110);
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  
  .date-picker-container:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .date-picker-container-checkbox {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 8px;
    right: 20px;
  }
  
  .date-picker-container-checkbox p {
    margin: 0;
    margin-right: 10px;
  }
  
  .options-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .option {
    padding: 7px 14px;
    border: 1px solid rgb(46, 76, 110);
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    flex: 1;
    margin: 0 5px;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    user-select: none;
  }
  
  .option.selected {
    background-color: #007bff;
    color: white;
  }
  
  .option:hover {
    background-color: #007bff;
    color: white;
    transform: translateY(-3px);
  }
  select.styled-select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgb(46, 76, 110);
    background-color: rgb(0, 0, 0);
    color: white;
    text-align: center;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }
  
  select.styled-select:focus {
    border-color: #007bff;
    outline: none;
  }
  
  select.styled-select option {
    background-color: white;
    color: black;
  }
  input.styled-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgb(46, 76, 110);
    background-color: rgb(0, 0, 0);
    color: white;
    width: 100%;
    max-width:220px;
    box-sizing: border-box;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  }
  
  input.styled-input:focus {
    border-color: #007bff;
    outline: none;
  }
}