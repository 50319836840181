.route__analytics__userStats {
    width: 100%;
    height: auto;
    
    &__filters {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;

        &__button {
            width: 80px;
            text-align: center;
            border-radius: 5px;
            border: none;
            color: white;
            font-size: 13px;
            font-family: "Inter";
            cursor: pointer;
            padding: 10px;
            margin-right: 10px;
            background-color: #48525C;
            border: 1px solid transparent;

            transition: border 0.3s ease, background-color 0.3s ease;

            &:hover {
                border: 1px solid #00a3ff;
            }
            &--active {
                background-color: rgb(0, 163, 255);
            }
            &.cloned-element {
                border: none;
            }
        }
    }
    &__tableWrap {
        width: 100%;
        height: auto;

        overflow-x: scroll;

        & > .customComponents__table {
            width: 4500px;
        }
    }
}

.route__analytics__customDate {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, 0.377);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: default;

    &__wrap {
        width: 500px;
        height: auto;
        padding: 20px;
        border-radius: 5px;

        display: flex;
        flex-direction: column;
        gap: 30px;

        background: #2f363e;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

        &__buttons {
            display: flex;
            gap: 100px;
            align-items: center;
            justify-content: center;
        }
    }
}