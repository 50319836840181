.route__buildABitch {
    width: 100%;
    height: auto;
    padding: 20px;

    &__addBitch {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        background: rgba(0, 0, 0, 0.65);
        color: white;
        cursor: default;

        &__wrap {
            position: relative;
            height: auto;
            max-height: 100%;
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 400px;
            margin-left: auto;
            margin-right: auto;
            padding: 30px;

            overflow-y: auto;

            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;

                background-color: rgba(0, 0, 0, 0.253);
                z-index: 999;
            }
            & .customComponents__input {
                width: 100%;
                margin-bottom: 20px;
            }
            &__controls {
                width: 100%;
                height: auto;

                display: flex;
                justify-content: space-between;
            }
        }
    }
    &__removeBitch {
        padding: 20px 0;

        & > button {
            margin: 10px;
        }
    }
}