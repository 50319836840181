.route__analitycs {
    width: 100%;
    height: 100%;
    padding: 20px;
    &__details{
        &__wrapper{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 15px;
            padding-bottom: 50px;
            &__block{
                width: 100%;
                min-height: 200px;
                max-height: 500px;
                overflow-y: scroll;
                background: #313943;
                padding: 10px;
                border-radius: 5px;
                box-shadow: inset 0 0 4px #48525c;
                font-size: 16px;
                h1{
                    font-size: 25px;
                    color: white;
                    font-family: 'Inter';
                    text-align: center;
                }

                &__item{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;
                    color: white;
                    font-family: 'Inter';
                    background: rgb(0, 163, 255);
                    padding: 3px;
                }
            }
        }
    }
    &__header{
        display: flex;
        flex-direction: row;
        justify-content: baseline;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
        span{
            background: #06234c;
            color: white;
            padding: 10px;
            cursor: pointer;
            font-family: 'Inter';
            border-radius: 5px;
            border: 1px solid #2E4C6E;
        }
    }
    &__filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div {
            & > button {
                text-align: center;
                border-radius: 5px;
                border: none;
                color: white;
                font-size: 13px;
                font-family: "Inter";
                cursor: pointer;
                padding: 10px;
                margin-right: 10px;
                background-color: #48525C;
            }
        }
    }
    &__table{
        border-radius: 10px;
        margin-top: 10px;
        overflow: hidden;
        &__head{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            color: white;
            background-color: rgba(25, 35, 67, 0.2);
            font-family: "inter";
            &>p{
                width: 25%;
                text-align: center;
            }
        }
        &__total{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            color: white;
            background-color: rgba(25, 35, 67, 0.2);
            font-family: "inter";
            &>p{
                width: 25%;
                text-align: center;
            }
        }
        &__content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            color: white;
            background-color: #48525C;
            font-family: "inter";
            &>p{
                width: 25%;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 1394px){
    .route__analitycs__details__wrapper__block__item{
        font-size: 14px;
    }
}

@media screen and (max-width: 1288px){
    .route__analitycs__details__wrapper__block__item{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}
@media screen and (max-width: 1022px){
    .route__analitycs__details__wrapper__block h1{
        font-size: 15px;
    }
}
@media screen and (max-width: 994px){
    .route__analitycs__details__wrapper__block{
        font-size: 15px;
    }
    .route__analitycs__details__wrapper{
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 788px){
    .route__analitycs__details__wrapper{
        grid-template-columns: 1fr;
    }
    .route__analitycs__details__wrapper__block__item{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (max-width: 406px){
    .route__analitycs__details__wrapper__block__item{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media screen and (max-width: 545px){
    .route__analitycs__filters div:nth-child(1){
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .route__analitycs__table__head > p, .route__analitycs__table__total > p, .route__analitycs__table__content > p{
        font-size: 14px;
    }
}