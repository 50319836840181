.route__emailServers{
    padding: 20px;
    &__editModal{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal .5s linear;
        top: 0;
        left: 0;

        &__selectDomainModal{
            padding: 20px;

            #box {
                background: #192343;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 2%;
                padding: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                max-height: 900px;
                padding: 20px;

                #table {
                    overflow-y: scroll;
                    max-height: 800px;
                    min-width: 100%;
                }

                #controles {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                }
            }
        }

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 400px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            padding: 30px;

            h1 {
                margin-bottom: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
            }

            #controles {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            #msg {
                color: red;
                font-family: 'Inter';
                text-align: center;
            }

            .selectDomainButton{
                width: 100%;
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
    &__addNewModal{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal .5s linear;
        top: 0;
        left: 0;

        &__selectDomainModal{
            padding: 20px;

            #box {
                background: #192343;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 2%;
                padding: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                max-height: 900px;
                padding: 20px;

                #table {
                    overflow-y: scroll;
                    max-height: 800px;
                    min-width: 100%;
                }

                #controles {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                }
            }
        }

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 400px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            padding: 30px;

            h1 {
                margin-bottom: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
            }

            #controles {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            #msg {
                color: red;
                font-family: 'Inter';
                text-align: center;
            }

            .selectDomainButton{
                width: 100%;
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 886px){
    .route__emailServers__addNewModal #box{
        width: 95%;
    }
    .route__emailServers__editModal #box{
        width: 95%;
    }
}