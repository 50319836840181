.component__contentSendMessage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-top: 1px solid #48525C;
    transform: rotate(-0.07deg);
    padding-top: 32px;
    padding-bottom: 32px;
    height: 90px;

    img{
        cursor: pointer;
    }

    textarea {
        background: #282F37;
        border: 1px solid #48525C;
        border-radius: 35px;
        width: 100%;
        outline: none;
        height: 41px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #FFFFFF;
        padding-left: 10px;
        padding-top: 5px;
        resize: none;
    }

    button {
        background: #48525C;
        border-radius: 100px;
        width: 163px;
        height: 42px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
    }
}