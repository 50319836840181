.route__packages {
    padding: 20px;
    &__editModal {
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-y: scroll;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal .5s linear;
        top: 0;
        left: 0;

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 400px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2%;
            padding: 30px;

            h1 {
                margin-bottom: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
            }

            #controles {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            #msg {
                color: red;
                font-family: 'Inter';
                text-align: center;
            }

            .selectDomainButton {
                width: 100%;
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 16px;
            }

            .route__packages__addNewModal__checkboxes{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-bottom: 15px;
                &__item{
                    display: flex;
                    justify-content: flex-start;
                    &>label{
                        font-family: "Inter";
                        color: white;
                        font-size: 15px;
                        padding-left: 15px;
                    }
                }
            }
            
        }
    }

    &__addNewModal {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal .5s linear;
        top: 0;
        left: 0;

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 400px;
            max-height: 90%;
            overflow-y: scroll;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2%;
            padding: 20px 30px;

            h1 {
                margin-bottom: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
            }

            #controles {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            #msg {
                color: red;
                font-family: 'Inter';
                text-align: center;
            }

            .selectDomainButton {
                width: 100%;
                margin-bottom: 20px;
                margin-top: 20px;
                font-weight: 700;
                font-size: 16px;
            }

            .route__packages__addNewModal__checkboxes{
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
                &__item{
                    margin-bottom: 20px;
                    &>label{
                        font-family: "Inter";
                        color: white;
                        font-size: 15px;
                        padding-left: 15px;
                    }
                }
                &__msg{
                    margin-top: 20px;
                    margin-bottom: 4px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

@media screen and (max-width: 911px){
    .route__packages__addNewModal #box{
        width: 95%;
        margin-top: 4%;
    }
    .route__packages__editModal #box{
        width: 95%;
        margin-top: 5%;
    }
}