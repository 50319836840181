.route__stream {
    width: 100%;
    height: 100%;
    padding: 20px;
    & > p {
        font-family: "Figtree";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: white;
    }
    &__chat{
        width: 100%;
        height: calc(100% - 100px);
        background-color: #282F37;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
        &__content {
            width: 100%;
            height: 100%;
            padding: 10px;
            overflow-y: scroll;
            & p {
                font-family: Figtree;
                font-size: 15px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 12px;
                word-break: break-all;
                color: white;
            }
            &__imageContainer {
                width: 200px;
                margin-bottom: 12px;
                & > img {
                    width: 100%;
                }
            }
        }
    }
}
