.component__checkbox {
    width: auto;
    height: auto;

    width: 20px;
    height: 20px;

    background: #242731;
    border: 1px solid #808191;
    border-radius: 4px;
    cursor: pointer;

    transition: border 0.3s ease, background-color 0.3s ease;

    & > img {
        opacity: 0;

        transition: opacity 0.3s ease;
    }
    &:hover {
        border-color: #6C5DD3;
    }
    &--active {
        background-color: #6C5DD3;

        & > img {
            opacity: 1;
        }
    }
    body.light & {
        background-color: #fff;
        border-color: #949393;
        
        &--active {
            background-color: #6C5DD3;
        }
        &:hover {
            border-color: #6C5DD3;
        }
    }
}