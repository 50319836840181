.route__transactionHistory {
    width: 100%;
    height: auto;

    padding: 20px;

    & .customComponents__table__data__customInput {
        max-width: 100%;
    }
    &__item {
        width: 100%;
        height: auto;

        &__pills {
            width: 100%;
            height: auto;

            display: grid;
            grid-template-rows: auto;
            grid-template-columns: max-content max-content max-content max-content;
            grid-auto-rows: auto;
            grid-auto-flow: row;

            row-gap: 10px;
            column-gap: 10px;

            & > strong {
                width: max-content;
                background: white;
                color: #2F363E;
                padding: 3px;
                border-radius: 5px;
                font-size: 12px;

                margin-right: 10px;

            }
        }
    }
}