.component__contentWrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: 250px 1fr;
}

@media screen and (max-width: 600px){
    .component__contentWrapper {
        grid-template-columns: 1fr;
    }
}