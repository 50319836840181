@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body, #root {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #080B16;
}

.root__internalSpinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.3s ease;
}
.root__internalSpinner p {
  color: white;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: rgb(85, 83, 83);
}

::-webkit-scrollbar-thumb:hover {
  background: #202122;
}

#root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
}

.route__wrapper{
  overflow-y: scroll;
}