.route__dashboardV2 {
    width: 100%;
    height: 100%;

    overflow: auto;
    background-color: #080B16;

    padding: 20px 55px;

    @media screen and (max-width: 587px) {
        padding: 20px;
    }

    &__head {
        color: #FFF;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;

        margin-bottom: 15px;
    }
    &__kpiPanel {
        width: 100%;
        height: auto;
        
        display: grid;
        grid-template-rows: 260px;
        grid-auto-rows: 260px;
        grid-template-columns: repeat(auto-fit, 230px);
        justify-content: center;
        column-gap: 20px;
        row-gap: 20px;

        transition: grid-template-rows 0.3s ease;

        &--filtered {
            grid-template-rows: 130px;
            grid-auto-rows: 130px;
        }
        &__kpi {
            position: relative;
            border-radius: 10px;
            background: linear-gradient(150deg, #11182F 0%, rgba(17, 24, 47, 0.50) 100%);

            display: grid;
            grid-template-rows: 34px 1fr;
            grid-template-columns: 100%;
            overflow: hidden;

            &__error {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                color: #FFF;
                leading-trim: both;
                text-edge: cap;
                font-family: Figtree;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%; /* 44.8px */

                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                text-align: center;

                z-index: 99;
                background-color: #1923438e;
                color: white;

                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;

                &--active {
                    opacity: 1;
                    pointer-events: all;
                }
            }
            &__head {
                width: 100%;
                height: auto;
                padding: 7px 20px;

                color: #FFF;
                font-family: Figtree;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */

                border-radius: 10px 10px 0px 0px;
                background: #192343;
            }
            &__wrap {
                width: 100%;
                height: 100%;
                padding: 20px;

                display: flex;
                flex-direction: column;
                gap: 20px;

                &__stats {
                    width: 100%;
                    height: auto;

                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    
                    &__top {
                        width: 100%;

                        display: flex;
                        justify-content: space-between;

                        &__left {
                            color: #FFF;
                            font-family: Figtree;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 16.8px */
                        }
                        &__right {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            color: #E717B3;
                            leading-trim: both;
                            text-edge: cap;
                            font-family: Figtree;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;

                            &--blue {
                                color: #4FB2F3;
                                leading-trim: both;
                                text-edge: cap;
                                font-family: Figtree;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }
                    }
                    &__bottom {
                        color: #FFF;
                        leading-trim: both;
                        text-edge: cap;
                        font-family: Figtree;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;
                        white-space: pre-wrap;
                    }
                }
                &__chart {
                    width: 100%;
                    height: 50px;
                }
                &__info {
                    width: 100%;
                    height: auto;

                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    &__data {
                        width: 100%;
                        height: auto;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &__name {
                            color: #FFF;
                            font-family: Figtree;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 16.8px */
                        }
                        &__value {
                            color: #FFF;
                            font-family: Figtree;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 16.8px */
                        }
                    }
                }
            }
        }
    }
    &__chart {
        width: 100%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20px;

        display: grid;
        grid-template-rows: 34px 366px;
        grid-template-columns: 100%;

        &__head {
            width: 100%;
            height: 100%;
            padding: 0 20px;

            color: #FFF;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */

            display: flex;
            align-items: center;

            border-radius: 10px 10px 0px 0px;
            background: #192343;
        }
        &__chart {
            width: 100%;
            height: 100%;

            display: grid;
            grid-template-rows: 60px calc(100% - 60px);
            grid-template-columns: 100%;

            background: linear-gradient(96deg, #11182F 0%, rgba(17, 24, 47, 0.50) 100%); 

            &__chartHead {
                width: 100%;
                height: 100%;
                padding: 0 20px;

                display: flex;
                align-items: center;
                gap: 30px;

                @media screen and (max-width: 587px) {
                    flex-direction: column;
                    gap: 0px;
                    padding-top: 4px;
                }

                &__item {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    & > p {
                        color: #FFF;
                        leading-trim: both;
                        text-edge: cap;
                        font-family: Figtree;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%; /* 16.8px */
                    }
                    & .component__customComponent__dropbox {
                        border: none;
                        box-shadow: none !important;
                        width: max-content;

                        & > .component__customComponent__dropbox__text {
                            color: #FFF;
                            leading-trim: both;
                            text-edge: cap;
                            font-family: Figtree;
                            font-size: 12px;
                            font-style: italic;
                            font-weight: 300;
                            line-height: 140%; /* 16.8px */
                        }
                    }
                    &__metrics {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        
                        font-family: Figtree;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%;

                        color: #FFF;
                        
                        &__metric {
                            padding: 5px;

                            background-color: #192343;
                            border-radius: 5px;
                            cursor: pointer;

                            transition: background-color 0.3s ease;

                            &:hover {
                                background-color: #334685;
                            }
                        }
                    }
                }
            }
            &__chartItem {
                width: 100%;
                height: 100%;
                padding: 0 20px;
                padding-bottom: 10px;
            }
        }
    }
    &__table {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-rows: 34px max-content;
        grid-template-columns: 1fr;

        &__head {
            width: 100%;
            height: 100%;
            padding: 0 20px;

            display: flex;
            align-items: center;

            color: #FFF;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */

            border-radius: 10px 10px 0px 0px;
            background: #192343;
        }
        &__filter{
            background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 0.5) 100%);
            padding: 20px;
            padding-bottom: 0px;
            display: grid;
            grid-template-columns: 2fr 2fr 2fr 1fr 1fr 150px;
            gap: 20px;
            &>input{
                outline: none;
                box-shadow: none;
                text-indent: 10px;
                background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 0.5) 100%);
                border: 1px solid #27386c;
                color: white;
            }
            &__item{
                padding: 10px;
                color: #FFF;
                font-family: Figtree;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.28px;
                text-transform: capitalize;
                border: 1px solid #27386c;
                border-radius: 5px;
                cursor: pointer;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                &__dropdown{
                    width: 100%;
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    left: 0;
                    top: 40px;
                    z-index: 999;
                    background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 1) 100%);
                    padding: 10px;
                    border: 1px solid #27386c;
                    border-radius: 5px;
                    max-height: 0px;
                    overflow: hidden;
                    padding: 0px;
                    border: 0px;
                    transition: 0.3s ease;
                    &>p{
                        padding: 4px 0px;
                        background: linear-gradient(94deg, #11182F 0%, #11182f 100%);
                        transition: 0.3s ease;
                        &:hover{
                            background: #27386c;
                        }
                    }
                }
            }
            &__btn{
                padding: 10px;
                color: #FFF;
                font-family: Figtree;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.28px;
                text-transform: capitalize;
                border: 1px solid #27386c;
                border-radius: 5px;
                cursor: pointer;
                position: relative;
                transition: 0.3s ease;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover{
                    background-color: #27386c;
                }
            }
            &__timeBox{
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #FFF;
                font-family: Figtree;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
                gap: 10px;
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                input[type=number] {
                    -moz-appearance: textfield;
                }
                &>input{
                    outline: none;
                    box-shadow: none;
                    background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 0.5) 100%);
                    border: 1px solid #27386c;
                    color: white;
                }
                &__day{
                    width: 30%;
                    min-width: 48px;
                    padding: 10px;
                    border-radius: 5px;
                    border: 1px solid #27386c;
                    display: flex;
                }
                &__hour{
                    width: 30%;
                    min-width: 48px;
                    padding: 10px;
                    border-radius: 5px;
                    border: 1px solid #27386c;
                    display: flex;
                }
                &__min{
                    width: 30%;
                    min-width: 48px;
                    padding: 10px;
                    border-radius: 5px;
                    border: 1px solid #27386c;
                    display: flex;
                }   
                &__sec{
                    width: 30%;
                    min-width: 48px;
                    padding: 10px;
                    border-radius: 5px;
                    border: 1px solid #27386c;
                    display: flex;
                }   
            }
        }
        &__content {
            width: 100%;
            height: auto;
            padding: 20px;

            border-radius: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 0.50) 100%);

            @media screen and (max-width: 587px) {
                padding: 12px 12px 12px 8px;
            }

            overflow-y: auto;

            &__table {
                width: auto;
                height: auto;
                min-width: 100%;
                border-radius: 10px;

                display: grid;
                grid-template-rows: auto;
                grid-auto-rows: auto;
                grid-template-columns: repeat(17, max-content);
                grid-auto-flow: column;
                column-gap: 30px;

                @media screen and (max-width: 587px) {
                    column-gap: 14px;
                }

                &__row {
                    width: auto;
                    max-width: 100%;

                    display: grid;
                    grid-column: 1 / -1;
                    grid-template-columns: subgrid;
                    grid-template-rows: subgrid;

                    background-color: #1923438e;

                    &:nth-child(2n+1) {
                        background-color: #192343;
                    }
                    &__column {
                        padding: 10px 8px;
                        color: #fff;
                        font-feature-settings: 'clig' off, 'liga' off;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }
                    &--head {
                        position: sticky;
                        top: 0;
                        z-index: 99;

                        background-color: #192343 !important;
                        color: #FFF;
                    }
                    &--head &__column {
                        color: #FFF;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px; /* 114.286% */
                        letter-spacing: 0.28px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
    &__usersActions{
        cursor: default;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.50);

        &__box {
            width: 80%;
            height: auto;
            max-height: 840px;
            background: #192343;
            border-radius: 5px;
            padding: 24px;
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            position: relative;

            #close{
                position: absolute;
                top: 10px;
                right: 10px;
                width: 25px;
                height: 25px;
            }

            // @media screen and (max-width: 750px) {
            //     width: 400px;
            // }
            @media screen and (max-width: 435px) {
            width: 100%;
                
            }
            &__tabs{
                display: flex;
                gap: 20px;
                margin-bottom: 16px;
                &>p{
                    padding: 10px 20px;
                    color: #FFF;
                    font-family: Figtree;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: 0.28px;
                    border: 1px solid #27386c;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: 0.3s ease;
                    &:hover{
                        background-color: #111931;
                    }
                }
            }
            &__container{
                width: 100%;
                height: auto;
                overflow: scroll;
                &__filter{
                
                    display: grid;
                    grid-template-columns: 2fr 2fr 2fr 1fr 1fr 150px;
                    gap: 20px;
                    &>input{
                        outline: none;
                        box-shadow: none;
                        text-indent: 10px;
                        background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 0.5) 100%);
                        border: 1px solid #27386c;
                        color: white;
                    }
                    &__item{
                        padding: 10px;
                        color: #FFF;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0.28px;
                        text-transform: capitalize;
                        border: 1px solid #27386c;
                        border-radius: 5px;
                        cursor: pointer;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 0.5) 100%);
                        &__dropdown{
                            width: 100%;
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            left: 0;
                            top: 0px;
                            z-index: 999;
                            background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 1) 100%);
                            padding: 10px;
                            border: 1px solid #27386c;
                            border-radius: 5px;
                            max-height: 0px;
                            overflow: hidden;
                            padding: 0px;
                            border: 0px;
                            transition: 0.3s ease;
                            &>p{
                                padding: 2px 0px;
                                background: linear-gradient(94deg, #11182F 0%, #11182f 100%);
                                transition: 0.3s ease;
                                &:hover{
                                    background: #27386c;
                                }
                            }
                        }
                    }
                    &__btn{
                        padding: 10px;
                        color: #FFF;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0.28px;
                        text-transform: capitalize;
                        border: 1px solid #27386c;
                        background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 1) 100%);
                        border-radius: 5px;
                        cursor: pointer;
                        position: relative;
                        transition: 0.3s ease;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:hover{
                            background-color: #27386c;
                        }
                    }
                    &__timeBox{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #FFF;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 19.6px */
                        gap: 10px;
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type=number] {
                            -moz-appearance: textfield;
                        }
                        &>input{
                            outline: none;
                            box-shadow: none;
                            background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 0.5) 100%);
                            border: 1px solid #27386c;
                            color: white;
                        }
                        &__day{
                            width: 30%;
                            min-width: 48px;
                            padding: 10px;
                            border-radius: 5px;
                            border: 1px solid #27386c;
                            display: flex;
                        }
                        &__hour{
                            width: 30%;
                            min-width: 48px;
                            padding: 10px;
                            border-radius: 5px;
                            border: 1px solid #27386c;
                            display: flex;
                        }
                        &__min{
                            width: 30%;
                            min-width: 48px;
                            padding: 10px;
                            border-radius: 5px;
                            border: 1px solid #27386c;
                            display: flex;
                        }   
                        &__sec{
                            width: 30%;
                            min-width: 48px;
                            padding: 10px;
                            border-radius: 5px;
                            border: 1px solid #27386c;
                            display: flex;
                        }   
                    }
                }
                &__items{
                    display: flex;
                    flex-direction: column;
                    overflow-y: scroll;
                    &__head{
                        margin-top: 20px;
                        margin-bottom: 20px;
                        color: #FFF;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0.28px;
                        display: grid;
                        grid-template-columns: 150px 100px 150px 180px 1fr;
                    }
                    &--item{
                        margin-bottom: 6px;
                        margin-top: 6px;
                        border-top: 1px solid #27386c;
                        background-color: #111931;
                        padding-top: 4px;
                        padding-bottom: 6px;
                        padding-left: 5px;
                        color: #FFF;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0.28px;
                        display: grid;
                        grid-template-columns: 150px 100px 150px 180px 1fr;
                        &>p{
                            padding-right: 10px;
                        }
                    }
                }
                &__sessionItems{
                    display: flex;
                    flex-direction: column;
                    overflow-y: scroll;
                    &__head{
                        margin-top: 20px;
                        margin-bottom: 0px;
                        color: #FFF;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0.28px;
                        display: grid;
                        grid-template-columns: 110px 110px 110px 110px 110px 1fr;
                    }
                    &--item{
                        margin-bottom: 6px;
                        margin-top: 6px;
                        border-top: 1px solid #27386c;
                        background-color: #111931;
                        padding-top: 4px;
                        padding-bottom: 6px;
                        padding-left: 5px;
                        color: #FFF;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0.28px;
                        display: grid;
                        grid-template-columns: 110px 110px 110px 110px 110px 1fr;
                        &>p{
                            padding-right: 10px;
                        }
                    }
                    &__statHead{
                        margin-top: 20px;
                        margin-bottom: 0px;
                        color: #FFF;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0.28px;
                        display: grid;
                        grid-template-columns: 110px 110px 110px 110px 110px 1fr;
                    }
                    &__statDate{
                        margin-top: 6px;
                        margin-bottom: 0px;
                        color: #FFF;
                        border-top: 1px solid #27386c;
                        background-color: #111931;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0.28px;
                        padding-top: 4px;
                        padding-bottom: 6px;
                        padding-left: 5px;
                        display: grid;
                        grid-template-columns: 110px 1fr;
                    }
                    &__statItem{
                        display: grid;
                        &--elems{
                            display: grid;
                            grid-template-columns: 110px 110px 110px 1fr;
                        }
                    }
                }
                &__completionItems{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    &__item{
                        display: grid;
                        grid-template-columns: 300px 1fr;
                        border-bottom: 1px solid #4285F4;
                        color: #fff;
                        padding-top: 8px;
                        padding-bottom: 4px;
                        padding-left: 4px;
                        background-color: #111931;
                        &>span{
                            &>a{
                                color: unset;
                            }
                        }
                    }
                }
                &__chats{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    &__head{
                        color: #FFF;
                        border-top: 1px solid #27386c;
                        background-color: #111931;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0.28px;
                        padding-top: 4px;
                        padding-bottom: 6px;
                        padding-left: 5px;
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        &>p{
                            &>span{
                                color: rgb(141, 255, 185);
                            }
                        }
                    }
                    &__messages{
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        color: #FFF;
                        border-top: 1px solid #27386c;
                        background-color: #111931;
                        font-family: Figtree;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0.28px;
                        padding-top: 4px;
                        padding-bottom: 6px;
                        padding-left: 5px;
                        &__item{
                            &>p{
                                &>span{
                                    color: rgb(141, 255, 185);
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
}