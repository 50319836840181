.route__users {
    @keyframes animateModal {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    animation: animateModal 0.1s linear;
    padding: 20px;

    &__addNewUserModal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal 0.5s linear;

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10%;
            padding: 20px;

            h1 {
                margin-bottom: 20px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #ffffff;
            }

            #controles {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            #roles {
                width: 100%;
                margin-bottom: 20px;

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: baseline;
                    align-items: center;
                    margin-top: 10px;

                    &:first-child {
                        margin-top: 0;
                    }

                    input {
                        margin-right: 10px;
                    }

                    p {
                        font-family: "Inter";
                        color: white;
                        font-size: 15px;
                    }
                }
            }

            #msg {
                color: red;
                font-family: "Inter";
                font-size: 15px;
                margin-top: 10px;
            }
        }
    }

    &__editUserModal {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal 0.5s linear;
        top: 0;
        left: 0;

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10%;
            padding: 20px;

            h1 {
                margin-bottom: 20px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #ffffff;
            }

            #controles {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            #roles {
                width: 100%;
                margin-bottom: 20px;

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: baseline;
                    align-items: center;
                    margin-top: 10px;

                    &:first-child {
                        margin-top: 0;
                    }

                    input {
                        margin-right: 10px;
                    }

                    p {
                        font-family: "Inter";
                        color: white;
                        font-size: 15px;
                    }
                }
            }

            #msg {
                color: red;
                font-family: "Inter";
                font-size: 15px;
                margin-top: 10px;
            }
        }
    }
}

.StreamerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        background-color: #2f363e;
        border-radius: 5px;
        position: relative;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
        }
        & > button {
            color: #fff;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px;
            margin-top: 40px;
            width: 250px;
            padding: 10px;
            background-color: rgb(63, 124, 234);
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}
.StreamerData {
    display: flex;
    align-items: center;
    justify-content: center;

    &__dropdown {
        display: grid;
        grid-template-columns: 50px 1fr;
        grid-template-rows: auto;
        column-gap: 20px;
        align-items: center;
        margin-bottom: 20px;

        & .component__customComponent__dropbox {
            height: 30px;
        }
    }
    & > div {
        width: 300px;
        height: auto;
        padding: 20px;
        background-color: #2f363e;
        border-radius: 5px;
        position: relative;
        
        & > p {
            padding: 10px 0px;
            text-align: left;
            & > button {
                color: #fff;
                font-family: Inter;
                line-height: 27px;
                padding:3px 15px;
                background-color: rgb(63, 124, 234);
                border: none;
                border-radius: 5px;
                cursor: pointer;
                margin-top: 5px;
                width: 100%;
            }
            &>input{
                width: 100%;
                margin-top: 5px;
                padding: 3px 10px;
                background-color: transparent;
                color: white;
            }
        }
    }
}

@media screen and (max-width: 911px) {
    .route__users__addNewUserModal #box {
        width: 95%;
    }
    .route__users__editUserModal #box {
        width: 95%;
        margin-top: 25%;
    }
}
