.component__tools{
    animation: animateTab .5s linear;
    padding: 20px;
    &__button{
        width: fit-content;
        background: #48525C;
        padding: 5px;
        font-family: 'Inter';
        font-size: 16px;
    }
}