.route__ladies {
    padding: 20px;
    #table-country {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        strong {
            background: white;
            color: #2F363E;
            padding: 3px;
            border-radius: 5px;
            font-size: 12px;
        }
    }

    &__editModal {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal .5s linear;
        top: 0;
        left: 0;

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 700px;
            max-height: 700px;
            overflow-y: scroll;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            padding: 10px;

            h1 {
                margin-bottom: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
            }

            #gender-select{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 10px;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background: rgb(72, 82, 92);
                    width: 100%;
                    height: 30px;
                    border-radius: 5px;
                    color: white;
                    font-family: 'Inter';
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 16px;
                    text-transform: uppercase;
                }
            }

            #gridbox {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                padding: 10px;

                #edit-photo-btns {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    gap: 10px;
                }

                #ladie-img-input,
                #ladie-checkbox {
                    margin-top: 10px !important;
                }

                #ladie-checkbox {
                    margin-left: 0 !important;
                    margin-right: auto !important;
                    display: block !important;
                }

                #languages-span,
                #interests-span {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    place-items: center;
                    gap: 10px;
                    max-height: 50px;
                    max-width: 300px;
                    overflow-y: scroll;

                    p {
                        background: #FFFFFF;
                        min-width: 70px;
                        width: fit-content;
                        border-radius: 3px;
                        padding: 3px;
                        margin-right: 10px;
                        text-align: center;
                        font-family: 'Inter';
                        font-size: 12px;
                        color: black;
                    }
                }

                .addNewLadieBtn {
                    width: 100%;
                    font-family: 'Inter';
                    font-weight: 700;
                    font-size: 16px;
                }
            }

            #controles {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            #msg {
                color: red;
                font-family: 'Inter';
                text-align: center;
            }
        }
    }

    &__addNewModal {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal .5s linear;
        top: 0;
        left: 0;

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 700px;
            max-height: 700px;
            overflow-y: scroll;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            padding: 10px;

            #gender-select{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 10px;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background: rgb(72, 82, 92);
                    width: 100%;
                    height: 30px;
                    border-radius: 5px;
                    color: white;
                    font-family: 'Inter';
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 16px;
                    text-transform: uppercase;
                }
            }

            h1 {
                margin-bottom: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
            }

            #gridbox {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                padding: 10px;

                #ladie-img-input,
                #ladie-checkbox {
                    margin-top: 10px !important;
                }

                #ladie-checkbox {
                    margin-left: 0 !important;
                    margin-right: auto !important;
                    display: block !important;
                }

                #languages-span,
                #interests-span {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    place-items: center;
                    gap: 10px;
                    max-height: 50px;
                    max-width: 300px;
                    overflow-y: scroll;

                    p {
                        background: #FFFFFF;
                        min-width: 70px;
                        width: fit-content;
                        border-radius: 3px;
                        padding: 3px;
                        margin-right: 10px;
                        text-align: center;
                        font-family: 'Inter';
                        font-size: 12px;
                    }
                }

                .addNewLadieBtn {
                    width: 100%;
                    font-family: 'Inter';
                    font-weight: 700;
                    font-size: 16px;
                }

                .lookingForWrap{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 10px;
                    margin-bottom: 15px;

                    .lookingForWrap__item{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 4px;
                        border: 1px solid white;
                        color: #fff;
                        border-radius: 10px;
                        font-size: 14px;
                        cursor: pointer;
                    }
                    .lookingForWrap__item--active{
                        border-color: #ee6dce;
                        color: #ee6dce;
                    }
                }
            }

            #controles {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            #msg {
                color: red;
                font-family: 'Inter';
                text-align: center;
            }
        }

        &__uploadPhotosModal {
            #box {
                background: #2F363E;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                width: 500px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 2%;
                padding: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                #box-upload {
                    display: flex;
                    flex-direction: row;
                    justify-content: baseline;
                    align-items: center;
                    width: 100%;

                    .uploadButton {
                        width: fit-content;
                        height: fit-content;
                        padding: 10px;
                    }
                }

                #controles {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                }

                #ladie-img-input {
                    margin-top: 20px !important;
                }

                #uploaded-links {
                    max-height: 200px;
                    height: 50px;
                    background: white;
                    width: 100%;
                    margin-top: 20px;
                    overflow-y: scroll;
                    padding: 5px;

                    span {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        padding-bottom: 3px;
                        border-bottom: 1px solid gray;

                        p {
                            color: gray;

                        }

                        #delete-photo {
                            color: red;
                        }
                    }
                }

                #msg {
                    color: red;
                    margin-top: 10px;
                }
            }
        }

        &__selectDomainModal {
            padding: 20px;

            #box {
                background: #2F363E;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 2%;
                padding: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                max-height: 900px;
                padding: 20px;

                #table {
                    overflow-y: scroll;
                    max-height: 800px;
                    min-width: 100%;
                }

                #controles {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                }
            }
        }
    }

    &__moreDetailsModal {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.65);
        animation: animateModal .5s linear;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;

        #box {
            background: #192343;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2%;
            padding: 40px;
            cursor: default;

            #domain-id {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
                font-family: 'Inter';
                font-size: 15px;
                width: 100%;
                border: 1px solid white;
                background: rgb(72, 82, 92);
                border-radius: 2px;

                p {
                    margin-left: 10px;
                }
            }

            #gridbox {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 10px;

                img {
                    width: 200px;
                    height: 200px;
                }

                #controles {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                }

                #top-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: baseline;
                    align-items: baseline;

                    p {
                        margin-bottom: 10px;
                        font-family: 'Inter';
                        font-size: 14px;
                        border-bottom: 1px solid white;
                        padding-bottom: 2px;
                        width: 100%;
                        text-align: left;
                    }
                }

                #middle-left,
                #middle-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: baseline;
                    align-items: baseline;

                    p {
                        margin-top: 10px;
                        font-family: 'Inter';
                        font-size: 14px;
                        border-bottom: 1px solid white;
                        padding-bottom: 2px;
                        width: 100%;
                        text-align: left;
                    }
                }

                #middle-left,
                #middle-right {
                    span {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: baseline;
                        padding-bottom: 2px;
                        width: 100%;
                        text-align: left;

                        p {
                            font-family: 'Inter';
                        }

                        strong {
                            font-family: 'Inter';
                            font-size: 14px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }

        &__morePhotosModal {
            #box {
                background: #2F363E;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                width: fit-content;
                max-width: 1500px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 2%;
                padding: 40px;
                display: flex;
                flex-direction: column;

                #images-box {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    gap: 10px;

                    img {
                        width: 200px;
                        height: 200px;
                    }
                }

                #controles {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1132px) {
    .route__ladies__moreDetailsModal {
        max-height: 100%;
        overflow-y: scroll;
    }

    .route__ladies__moreDetailsModal__morePhotosModal #box {
        width: 95%;
    }

    .route__ladies__moreDetailsModal__morePhotosModal #box #images-box {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 731px) {
    .route__ladies__addNewModal #box {
        width: 95%;
    }

    .route__ladies__editModal #box {
        width: 95%;
    }

    .route__ladies__moreDetailsModal__morePhotosModal #box #images-box {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 610px) {
    .route__ladies__editModal #box #gridbox {
        grid-template-columns: 1fr;
    }

    .route__ladies__editModal {
        overflow-y: scroll;
        max-height: 100%;
    }

    .route__ladies__editModal #box {
        margin-top: 2%;
    }

    .route__ladies__addNewModal__selectDomainModal #box {
        margin-top: 0%;
    }
    .route__ladies__editModal #box, .route__ladies__addNewModal #box{
        max-height: fit-content;
    }
    .route__ladies__editModal #box #gridbox #languages-span, .route__ladies__editModal #box #gridbox #interests-span{
        max-width: 100%;
    }
}

@media screen and (max-width: 543px) {
    .route__ladies__addNewModal #box #gridbox {
        grid-template-columns: 1fr;
    }

    .route__ladies__addNewModal__uploadPhotosModal #box {
        width: 95%;
    }

    .route__ladies__addNewModal__uploadPhotosModal #box {
        width: 95%;
    }

    .route__ladies__editModal #box #gridbox #edit-photo-btns {
        grid-template-columns: 1fr;
    }

    .route__ladies__moreDetailsModal #box {
        width: 95%;
    }

    .route__ladies__moreDetailsModal #box #gridbox {
        grid-template-columns: 1fr;
    }

    .route__ladies__moreDetailsModal #box #gridbox img {
        margin-bottom: 20px;
    }

    .route__ladies__moreDetailsModal #box #domain-id {
        flex-direction: column;
    }
}

@media screen and (max-width: 486px) {
    .route__ladies__moreDetailsModal__morePhotosModal #box #images-box {
        grid-template-columns: 1fr;
    }

    .route__ladies__moreDetailsModal__morePhotosModal {
        max-height: 100%;
        overflow-y: scroll;
    }
}