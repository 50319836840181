.route__chatStatistics{
  padding: 20px 55px;

  width: 100%;
  height: 100%;

  overflow: auto;
  background-color: #080B16;

  @media screen and (max-width: 587px) {
    padding: 20px;
  }

  &__usersTable{
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__gents{
      display: flex;
      flex-direction: column;
      background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 0.5) 100%);
      overflow-y: auto; 
      max-height: 600px;
      border: 1px solid #2E4C6E;
      &__headline{
        color: #FFF;
        font-family: Figtree;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.28px;
        padding: 8px 20px;
        text-transform: capitalize;
      }
      &__head{
        display: grid;
        grid-template-columns: 200px 140px 140px 140px 150px 120px 150px 150px 150px;
        color: #FFF;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.28px;
        padding: 10px 20px;
        background-color: #162d47;
        border-bottom: 1px solid #2E4C6E;
        width: max-content;
        text-transform: capitalize;
        gap: 10px;
        position: sticky;
        top: 0;
        &>p{
          text-align: left;
          word-break: break-word;
          max-width: 200px;
        }
      }
      &__item{
        display: grid;
        grid-template-columns: 200px 140px 140px 140px 150px 120px 150px 150px 150px;
        gap: 10px;
        width: max-content;
        &>p{
          text-align: left;
          word-break: break-word;
          max-width: 200px;
          padding: 10px 8px;
          padding-left: 20px;
          color: #fff;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Figtree;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
    &__ladies{
      display: flex;
      flex-direction: column;
      background: linear-gradient(94deg, #11182F 0%, rgba(17, 24, 47, 0.5) 100%);
      overflow-y: auto;
      max-height: 600px;
      border: 1px solid #2E4C6E;
      &__headline{
        color: #FFF;
        font-family: Figtree;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.28px;
        padding: 8px 20px;
        text-transform: capitalize;
      }
      &__head{
        display: grid;
        grid-template-columns: 200px 140px 140px 140px 150px 120px 150px;
        color: #FFF;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.28px;
        padding: 10px 20px;
        text-transform: capitalize;
        gap: 10px;
        background-color: #162d47;
        border-bottom: 1px solid #2E4C6E;
        width: max-content;
        position: sticky;
        top: 0;
        &>p{
          text-align: left;
          word-break: break-word;
          max-width: 200px;
        }
      }
      &__item{
        display: grid;
        grid-template-columns: 200px 140px 140px 140px 150px 120px 150px;
        gap: 10px;
        width: max-content;
        &>p{
          text-align: left;
          word-break: break-word;
          max-width: 200px;
          padding: 10px 8px;
          padding-left: 20px;
          color: #fff;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Figtree;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

}