.route__secondaryUsers {
  padding: 20px;

  &__moreDetailsModal {
    user-select: text;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, 0.65);
    animation: animateModal .5s linear;
    top: 0;
    left: 0;

    #box {
      background: #192343;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      width: 500px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2%;
      padding: 40px;
      cursor: default;

      #domain-id {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        font-family: 'Inter';
        font-size: 15px;
        width: 100%;
        border: 1px solid white;
        background: rgb(72, 82, 92);
        border-radius: 2px;

        p {
          margin-left: 10px;
        }
      }

      #gridbox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;

        img {
          width: 200px;
          height: 200px;
        }

        #controles {
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }

        #top-right {
          display: flex;
          flex-direction: column;
          justify-content: baseline;
          align-items: baseline;

          p {
            margin-bottom: 10px;
            font-family: 'Inter';
            font-size: 14px;
            border-bottom: 1px solid white;
            padding-bottom: 2px;
            width: 100%;
            text-align: left;
          }
        }

        #middle-left,
        #middle-right {
          display: flex;
          flex-direction: column;
          justify-content: baseline;
          align-items: baseline;

          p {
            margin-top: 10px;
            font-family: 'Inter';
            font-size: 14px;
            border-bottom: 1px solid white;
            padding-bottom: 2px;
            width: 100%;
            text-align: left;
          }
        }

        #middle-left,
        #middle-right {
          span {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: baseline;
            padding-bottom: 2px;
            width: 100%;
            text-align: left;

            p {
              font-family: 'Inter';
            }

            strong {
              font-family: 'Inter';
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  #table-country {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    strong {
      background: white;
      color: #2F363E;
      padding: 3px;
      border-radius: 5px;
      font-size: 12px;
    }
  }

  &__addCredits {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.6);

    &__wrap {
      width: 300px;
      height: auto;
      padding: 20px;
      background-color: #192343;
      border-radius: 5px;
      position: relative;

      &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.450);
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.3s ease;
      }

      &__infoP {
        text-align: start;
        margin-top: 20px;
        color: #ff7474;
      }
    }
  }
}

@media screen and (max-width: 1132px) {
  .route__secondaryUsers__moreDetailsModal #box {
    width: 95%;
  }

  .route__secondaryUsers__moreDetailsModal {
    overflow-y: scroll;
    max-height: 100%;
  }
}

@media screen and (max-width: 544px) {
  .route__secondaryUsers__moreDetailsModal #box #gridbox {
    grid-template-columns: 1fr;
  }

  .route__secondaryUsers__moreDetailsModal #box #gridbox img {
    margin-bottom: 20px;
  }

  .route__secondaryUsers__moreDetailsModal #box #domain-id {
    flex-direction: column;
  }

  .route__secondaryUsers #table-country {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}