.route__chat{
    display: grid;
    grid-template-columns: 360px 1fr;
    height: 100%;
    padding: 20px;
    &__button{
        position: fixed;
        z-index: 3;
        display: none;
        button{
            background: #48525C;
            color: white;
            border: none;
            padding: 5px;
            cursor: pointer;
        }
    }
    
}

@media screen and (max-width: 1100px){
    .route__chat{
        grid-template-columns: 1fr;
        padding: 10px;
        &__button{
            display: block;
        }
    }
}